.Signup-Container {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  background: radial-gradient(circle at 300%, #0f142b 10%, #f42c74 10%, #0f142b 100%);
}

.Signup {
  position: relative;
  top: 0px;
  padding-top: 20px;
  padding-bottom: 80px;
  font-family: "Montserrat";
  overflow-y: auto;
  width: 100%;
  height: 100%;
  color: #b3b3b3;
}

.Signup form {
  margin: 0 auto;
  max-width: 320px;
  width: 100%;
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #b3b3b3;
}

.Signup-Heading-Container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
}

.Signup-Heading {
  margin: 0 auto;
  width: 100%;
  max-width: 320px;
  font-size: 20pt;
  color: #fff;
  font-weight: bold;
  font-family: "Montserrat";
}

.Signup-Heading-Item {
  margin-right: 10px;
}

.Signup-Heading-Icon {
  cursor: pointer;
}

.Signup-Input {
  font-size: 14pt;
  color: #fff;
}

.Signup-Group {
  margin-bottom: 20px;
}

.Signup-Group-2 {
  margin-top: 5px;
  margin-bottom: 55px;
}

.Signup-Group-3 {
  margin-bottom: 10px;
}

.Signup-Welcome {
  text-align: center;
  font-weight: bold;
  font-size: 24pt;
}

.Signup-Default-Text {
  font-size: 18pt;
  font-weight: bold;
  font-family: "Lato";
}

.Signup-Button {
  position: relative;
  margin-top: 25%;
  bottom: 25px;
}

.Signup-Field {
  border-radius: 15px !important;
}

.Signup-Hint {
  font-size: 9pt;
  color: #fff;
  text-align: left;
}

.Signup-Photo-Container {
  margin: 0 auto;
  max-width: 320px;
  width: 100%;
  color: #fff;
  font-size: 48pt;
  text-align: center;
}

.Signup-Profile {
  border: 2px solid #fff;
  border-radius: 50%;
  margin: 0 auto;
  width: 250px;
  height: 250px;
}

.Signup-Profile input {
  display: none
}

.Signup-Profile-Icon {
  padding: 40px;
  padding-top: 50px;
}

.Signup-Profile-Form {
  margin: 20px auto;
  max-width: 320px;
  width: 100%;
  text-align: center;
}

.Signup-Profile-Text {
  font-size: 24pt;
  cursor: pointer;
  color: #fff;
  transition: .25s all ease;
}

.Signup-Profile-Text:hover {
  color: #b3b3b3
}

.Signup-Profile-Text input {
  display: none;
}

.Signup-Legal {
  position: absolute;
  top: 10vh;
  height: 80vh;
  width: 86%;
  left: 7%;
  overflow-y: auto;
  font-family: "Lato";
}

.Signup-Checkbox {
  margin-top: 5px;
  font-size: 12pt;
  color: #b3b3b3;
}

.Signup-Checkbox-Text {
  margin-left: 5px;
}

.Signup-Link {
  text-decoration: underline;
  cursor: pointer;
  transition: .25s all ease;
}

.Signup-Link:hover {
  text-shadow: 0 0 8px #fff;
}

.Signup-Legal-Close {
  font-size: 14pt;
  cursor: pointer;
  color: #fff;
  width: 100%;
  text-align: right;
  background-color: #0f142b;
  padding: 5px;
  transition: .25s all ease;
}

.Signup-Legal-Close:hover {
  color: #ff0000;
}

.Signup-Default-Container {
  margin-top: 50px;
}

.Signup-Label-Left {
  width: 100%;
  text-align: left;
}

.Signup-Heading-Checkmark {
  height: 20px;
  margin-top: 40px;
}

.Signup-Nodes-Section {
  width: 100%;
  height: 30px;
  font-size: 14pt;
  font-weight: bold;
  font-family: "Montserrat";
  padding: 1px;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  cursor: pointer;
}

.Signup-Options-Container {
  width: 90%;
  margin: 10px 5%;
}

.Signup-Node-Container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
}

.Signup-Node-Item {
  width: 100px;
  height: 100px;
  padding: 5px;
  margin: 5px;
  border: 1pt solid #fff;
  border-radius: 15px;
  box-shadow: 5px 5px 8px #000;
}

.Signup-Node-Item:hover {

  cursor: pointer;
}

.Signup-Node-Item-Selected {
  border: 2pt solid #fff;
  box-shadow: 2px 2px 10px #fff;
  text-shadow: 1px 1px 8px #fff;
}

.Signup-Node-Item-Text {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 11pt;
  color: #fff;
  padding-top: 18%;
  font-weight: bold;
}

.Signup-Amenities-Section {
  width: 100%;
  padding: 5px;
  text-align: center;
  border: 1pt solid #b3b3b3;
  border-radius: 15px;
  font-size: 14pt;
}

.Signup-LiveMusic {
  border: 1px solid #b3b3b3;
  background: linear-gradient(to bottom right, #d233c2, #390417);
}

.Signup-Venue {
  border: 1px solid #b3b3b3;
  background: linear-gradient(to bottom right, #704ce3, #190753);
}

.Signup-Entertainment {
  border: 1px solid #b3b3b3;
  background: linear-gradient(to bottom right, #d233c2, #390417);
}

.Signup-Entertainer {
  border: 1px solid #b3b3b3;
  background: linear-gradient(to bottom right, #d233c2, #390417);
}

.Signup-Equipment {
  border: 1px solid #f1b9f9;
  background: linear-gradient(to bottom right, #f1b9f9, #3d3d3d);
}

.Signup-Security {
  border: 1px solid #f1b9f9;
  background: linear-gradient(to bottom right, #f1b9f9, #3d3d3d);
}

.Signup-Catering {
  border: 1px solid #f1b9f9;
  background: linear-gradient(to bottom right, #f1b9f9, #3d3d3d);
}

.Signup-Artist {
  border: 1px solid #b3b3b3;
  background: linear-gradient(to bottom right, #d233c2, #390417);
}

@media all and (min-width: 390px) {
  .Login {
    padding: 60px 0;
    font-family: "Montserrat"
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
    width: 100%;
  }
}

.Login-Heading-Container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
}

.Login-Heading {
  margin: 0 auto;
  width: 100%;
  max-width: 320px;
  font-size: 20pt;
  color: #fff;
  font-weight: bold;
}

.Login-Heading-Item {
  margin-right: 10px;
}

.Login-Button {
  margin-top: 25%;
  bottom: 0;
}

.Login-Field {
  border-radius: 15px !important;
}

.Login-Link {
  margin-top: 10px;
}

.Login-Checkmark-Container {
  margin: 0 auto;
  max-width: 320px;
  width: 100%;
}

.Login-Checkmark {
  margin-left: 10%;
  width: 80%;
}

.Signup-Heading-2 {
  width: 100%;
  padding: 5px;
  padding-bottom: 10px;
  font-size: 18pt;
  color: #fff;
  font-weight: bold;
  font-family: "Montserrat";
  text-align: center;
}

.Signup-List {
  margin: 0 auto;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 11pt;
  color: #fff;
}

.Signup-Options-Reset {
  margin: 10px 0;
  font-size: 14pt;
  color: #fff;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  transition: .25s all ease;
  cursor: pointer;
}

.Signup-Options-Reset:hover {
  color: #b3b3b3;
}

.Signup-List-2 {
  margin: 0 auto;
  width: 80%;
  font-size: 11pt;
  color: #fff;
}

.Signup-Confirm {
  position: relative;
  top: -50vh;
  width: 300px;
  margin: 0 auto;
  background-color: #5f5e5e;
  box-shadow: 5px 5px 8px #000;
  border: 3px solid #000;
  border-radius: 15px;
  padding: 5px;
  z-index: 2;
}

.Signup-Confirm-Text {
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: 14pt;
}

.Signup-Row {
  width: 100%;
  margin-bottom: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.Signup-Flex-Wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Signup-Confirm-Button {
  width: 45%;
  text-align: center;
  border-radius: 20px;
  font-size: 14pt;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  padding: 10px;
  transition: .25s all ease;
  cursor: pointer;
  background: linear-gradient(180deg, #f52c74, #390417);
  border: 2px solid #f52c74;
}

.Signup-Confirm-Button:hover {
  box-shadow: 0 0 8px #fff;
}

.Signup-Strong {
  font-weight: bold;
  color: green;  
}

.Signup-Weak {
  text-decoration: underline;
  color: #696969;
  font-weight: bold;
}

.Info-Link {
  width: 100%;
  text-align: center;
  font-size: 16pt;
  font-weight: bold;
  color: blue;
}
