.MiniWide-Container {
    width: 390px;
    height: 113px;
    margin: 3px 0px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-radius: 20px;
}

.MiniWide-Container-event {
    border: 3px solid #f52c74;
}

.MiniWide-Container-venue {
    border: 3px solid #6e4ae0;
}

.MiniWide-Container-default {
    border: 3px solid #3d3d3d;
}

.MiniWide-Section {
    height: 100%;
    padding: 3px;
}

.MiniWide-Left {
    width: 85px;
    padding: 5px;
    padding-top: 10px;
}

.MiniWide-Middle {
    width: 240px;
}

.MiniWide-Right {
    width: 50px;
    color: #fff;
}

.MiniWide-Row {
    width: 100%;
    min-height: 31px;
}

.MiniWide-Mid-Top {
    width: 100%;
    height: 48px;
}

.MiniWide-Mid-Mid {
    width: 100%;
    height: 24px;
}

.MiniWide-Mid-Bottom {
    width: 100%;
    height: 29px;
}

.MiniWide-Flex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
}

.MiniWide-Flex-2 {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.MiniWide-Item {
    margin-right: 5px;
    color: #fff;
    font-size: 12pt;
}

.MiniWide-Title {
    font-family: 'Montserrat';
    font-size: 12pt;
    color: #fff;
}

.MiniWide-Text {
    font-family: 'Lato';
    font-size: 10pt;
    color: #fff;
}

.MiniWide-CI {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: fill;
    opacity: .5;
    border: 1px solid #f52c74;
}

.MiniWide-CI-Alt {
    font-size: 32pt;
    color: #fff;
    text-align: center;
}

.MiniWide-Stars {
    font-size: 24pt;
    color: yellow;
    margin-top: -11px;    
}

.MiniWide-Stars-Text {
    width: 35px;
    margin-left: -34px;
    font-size: 9pt;
    font-weight: bold;
    margin-top: 9px;
    color: #000;
    text-align: center;
}

.MiniWide-Heart-Text {
    margin-left: -30px;
    font-size: 9pt;
    font-weight: bold;
    margin-top: 5px;
    color: #000;
    text-align: center;
    width: 30px;
}

.MiniWide-Heart {
    font-size: 22pt;
    color: #f52c74;
    margin-top: -10px;  
}

.MiniWide-Icon {
    font-size: 18pt;
}

.MiniWide-Nearby {
    border: 2px solid #f52c74;
    border-radius: 15px;
    padding: 2px;
    
    margin-top: 0px;
}