.TopMenu-Container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.TopMenu-BG-fan {
    background: linear-gradient(to bottom, #704ce3, #190753);
}

.TopMenu-BG-Venue {
    background: linear-gradient(to bottom, #190244, #190753);
}

.TopMenu-BG-Contributor {
    background: linear-gradient(to bottom, #f52c74, #390417);
}

.TopMenu-Search {
    width: 50%;
}

.TopMenu-Profile {
    width: 40px;
    height: 40px;
    padding: 1px;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.TopMenu-Profile-fan {
    border: 1px solid #f52c74;
    box-shadow: 0 0 5px #f52c74;
}

.TopMenu-Profile-venue {
    border: 1px solid #f52c74;
    box-shadow: 0 0 5px #f52c74;
}

.TopMenu-Profile-contributor {
    border: 1px solid #f52c74;
    box-shadow: 0 0 5px #f52c74;
}

.TopMenu-Left {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
}

.TopMenu-Right {
    display: flex;
    flex-wrap: nowrap;
    justify-content: right;
    margin-right: 5px;
}

.TopMenu-Item {
    color: #fff;
    padding: 8px;
    margin: 3px;
    text-align: center;
    font-size: 18pt;
    cursor: pointer;
}

.TopMenu-Item-Sel {
    border-bottom: 2px solid #fff;
}

.TopMenu-Notifications {
    position: relative;
    width: 20px;
    background-color: #ff0000;
    border-radius: 50%;
    color: #fff;
    font-size: 8pt;
    font-weight: bold;
    top: -30px;
    left: 10px;
    z-index: 0;
}

.TopMenu-Img {
    width: 40px;
    height: 40px;
    padding: 1px;
    margin: 5px;
    border: 1px solid #f52c74;
    border-radius: 50%;
    box-shadow: 0 0 5px #f52c74;
    cursor: pointer;
}

.TopMenu-User {
    width: 40px;
    height: 40px;
    color: #fff;
    margin-top: 5px;
    border: 1px solid #f52c74;
    border-radius: 50%;
    box-shadow: 0 0 5px #f52c74;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    font-family: "Lato";
}

.TopMenu-User svg {
    font-size: 27pt;
    margin-top: -4px;
    margin-left: -4px;
}

.Settings-Container {
    width: 300px;
    height: 200px;
    position: absolute;
    top: 51px;
    right: 30px;
    padding-top: 15px;
    border-radius: 30px;
    border: 2px solid #b3b3b3;
    box-shadow: 5px 5px 8px #000;
    background: linear-gradient(to bottom right, #190244, #534091);
    transition: .25s all ease;
    z-index: 2;
}

.Settings-Row {
    width: 100%;
    margin-bottom: 10px;
}

.Settings-Button {
    width: 230px;
    margin: 0 auto;
    border: 1px solid #b3b3b3;
    border-radius: 25px;
    padding: 5px;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14pt;
    font-weight: bold;
    color: #b3b3b3;
    cursor: pointer;
}

.Settings-Button:hover {
    box-shadow: 0 0 8px #fff;
}

.Settings-Venue {
    border: 3px solid #6e4ae0;
}

.Settings-Contributor {
    border: 3px solid #f52c74;
}

.Settings-Fan {
    border: 1px solid #f1b9f9;
}

.Settings-Current {
    box-shadow: 0 0 8px #fff;
}

.Settings-User {
    color: #000;
    background-color: #b3b3b3;
}

.Settings-Foul {
    width: 100%;
    margin-top: -0px;
    text-align: center;
    font-size: 11pt;
    color: #fff;
}

.Settings-Link {
    text-decoration: underline;
    cursor: pointer;
    transition: .25s all ease;
    text-shadow: 1px 0px 1px #b3b3b3;
}

.Settings-Link-Red {
    color: #f52c74;
}

.Settings-Link-Blue {
    color: #190753;
}

.Settings-Link:hover {
    font-size: 16pt;
}

.Search-Location-Checkmark {
    height: 20px;
    margin-top: 0px;
}

.Search-Container {
    width: 300px;
    position: absolute;
    top: 51px;
    left: 5px;
    padding: 5px 15px;
    padding-bottom: 35px;
    border-radius: 30px;
    border: 2px solid #b3b3b3;
    box-shadow: 5px 5px 8px #000;
    background: linear-gradient(to bottom right, #190244, #534091);
    max-height: calc(100vh - 105px);
    overflow-y: auto;
}

.Search-Row {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.Search-Label {
    font-size: 14pt;
    color: #fff;
    cursor: pointer;
}

.Search-Link {
    margin-top: 3px;
    font-size: 9pt;
    color: #fff;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
    transition: .25s all ease;    
}

.Search-Link:hover {
    font-size: 12pt;
}

.Search-Flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.Search-Spread {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;
}

.form-check input {
    margin-left: -15px;
    margin-top: 5px;
}