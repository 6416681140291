.BottomMenu-Container {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    z-index: 3;
    background-color: #0f142b;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.BottomMenu-Item {
    color: #fff;
    padding: 5px;
    margin-top: -14px;
    width: 80px;
    text-align: center;
    font-family: 'Montserrat';
    cursor: pointer;
}

.BottomMenu-Item-Text {
    font-size: 9pt;    
    margin-top: -7px;
}

.BottomMenu-Giglit-Container {
    width: 100px;
}

.BottomMenu-Giglit {
    position: relative;
    top: -15px;
    left: 5px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: #b3b3b3;
    text-align: center;
}

.BottomMenu-Giglit-Icon {
    font-size: 24pt;
    padding-top: 2px;

}

.BottomMenu-Item-Row {
    width: 100%;
    text-align: center;
}

.BottomMenu-Giglit-Red {
    background: radial-gradient(circle, #fff 5%, #f52c74);
    animation: blinkRed 1.2s infinite;
}

.BottomMenu-Giglit-Green {
    background: radial-gradient(circle, #fff 5%, green);
    animation: blinkGreen 1.2s infinite;
}

@keyframes blinkRed {
    from { background: radial-gradient(circle, #fff 5%, #f52c74); }
    10% { background: radial-gradient(circle, #fff 10%, #f52c74); }
    20% { background: radial-gradient(circle, #fff 25%, #f52c74); }
    30% { background: radial-gradient(circle, #fff 35%, #f52c74); }
    40% { background: radial-gradient(circle, #fff 50%, #f52c74); }
    50% { background: radial-gradient(circle, #fff 60%, #f52c74); }
    60% { background: radial-gradient(circle, #fff 50%, #f52c74); }
    70% { background: radial-gradient(circle, #fff 35%, #f52c74); }
    80% { background: radial-gradient(circle, #fff 25%, #f52c74); }
    90% { background: radial-gradient(circle, #fff 10%, #f52c74); }
    to { background: radial-gradient(circle, #fff 0%, #f52c74); }
  }

  @keyframes blinkGreen {
    from { background: radial-gradient(circle, #fff 5%, green); }
    10% { background: radial-gradient(circle, #fff 10%, green); }
    20% { background: radial-gradient(circle, #fff 25%, green); }
    30% { background: radial-gradient(circle, #fff 35%, green); }
    40% { background: radial-gradient(circle, #fff 50%, green); }
    50% { background: radial-gradient(circle, #fff 60%, green); }
    60% { background: radial-gradient(circle, #fff 50%, green); }
    70% { background: radial-gradient(circle, #fff 35%, green); }
    80% { background: radial-gradient(circle, #fff 25%, green); }
    90% { background: radial-gradient(circle, #fff 10%, green); }
    to { background: radial-gradient(circle, #fff 0%, green); }
  }
