.App-Loading-Bar {
  position: relative;
  margin-top: 50%;
  background-color: #190244;
  transition: .20s all ease-in
}

.App-Loading-Bar-Init {
  height: 5px;
  width: 0;
}

.App-Loading-Bar-Final {
  height: 5px;
  width: 100%;
}

.App-Loading-Bar-Post {
  margin-top: 0;
  width: 100%;
  height: 100vh;
}

.App-Faded {
  opacity: 0;
}

.App-Showing {
  opacity: 1;
}

.Gigloca-Container {
  width: 100vw;
  height: 100vh;
  background: radial-gradient(circle at 300%, #190244 90%, #330e79);
}

.Gigloca-Title {
  position: absolute;
  top: 42vh;
  color: #fff;
  font-size: 36pt;
  width: 100%;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.Gigloca-Text-1 {
  position: absolute;
  top: 55vh;
  color: #f52c74;
  font-size: 16pt;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-style: italic;  
  text-shadow: 5px 5px 8px #000;
}


.Gigloca-Text-2 {
  position: absolute;
  top: 75vh;
  color: #fff;
  font-size: 20pt;
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
}
