.Fan-Container {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    background: radial-gradient(circle at 300%, #000, #704ce3 10%, #0f142b 80% );
}

.Fan-No-Scroll {
    position: fixed;
    top: 50px;
    width: 100%;    
    overflow-y: hidden;
}

.Fan-NoMap-Container {
    width: 100%;
    color: #fff;
}

.Fan-NoMap-Icon {
    font-size: 26pt;
    width: fit-content;
    margin: 0 auto;
}

.Fan-NoMap-Text {
    text-align: center;
    width: 200px;
    margin: 0 auto;
    font-size: 24pt;
    font-weight: bold;
    font-style: italic;
    text-shadow: 5px 5px 8px #f52c74;
}

.Fan-NoResults-Text {
    width: 300px;
    margin: 20px auto;
    font-size: 18pt;
    font-weight: bold;  
    text-align: center;  
}

.Fan-Map-Container {
    width: 100%;
    z-index: 2;
}

.Map-Full {
    height: calc(100vh - 290px);
}

.Map-Half {
    height: calc(55vh - 145px);
}

.Map-Min {
    height: 0px;
}

.Fan-Swipe-Container {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    z-index: 2;
}

.Fan-Swipe-Bar {
    width: 35%;
    height: 4px;
    margin: 0px auto;
    margin-top: 25px;
    border: 2px solid #ccc;
    cursor: pointer;
    transition: .25s all ease;
}

.Fan-Swipe-Bar:hover {
    height: 6px;
    border: 3px solid #f52c74;
}

.Fan-HotTopics-Container {
    width: 100%;
    height: 30px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.Fan-HotTopics-Item {
    font-size: 18pt;
    color: #fff;
    width: 35px;
    height: 37px;
    cursor: pointer;
    text-align: center;
}

.Fan-Hotipics-Text {
    font-size: 6pt;
    width: 100%;
    text-align: center;
    margin-top: -3px;
}

.Fan-HotTopics-Venue {
    color:  #6e4ae0;
}

.Fan-HotTopics-Event {
    color:  #f52c74;
}

.Fan-Cards {
    position: fixed;
    width: 100%;
    height: calc(50vh + 30px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;    
    padding-bottom: 130px;
    overflow-y: auto;
    margin-top: 10px;
}

.Fan-More-Container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 10px;
}

.Fan-More-Divider {
    flex-grow: 100;
    height: 1px;
    border: 1px solid #b3b3b3;
    margin: 10px 10px;
}

.Fan-More-Button {
    border: 1pt solid #b3b3b3;
    color: #b3b3b3;
    border-radius: 25px;
    padding: 5px;
    cursor: pointer;
    font-size: 12pt;
    transition: .25s all ease;
    width: 75px;
    text-align: center;
    height: 37px;
}

.Fan-More-Button:hover {
    box-shadow: 0 0 5px #fff;
}
